// Generated by Framer (a4439fa)

import { addFonts, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {TRxvv7Kl_: {hover: true}};

const cycleOrder = ["TRxvv7Kl_"];

const serializationHash = "framer-02chZ"

const variantClassNames = {TRxvv7Kl_: "framer-v-1tqo0fn"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "TRxvv7Kl_", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 800, intrinsicWidth: 800, pixelHeight: 800, pixelWidth: 800, sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/qBaq2Pok61msdnJ2jr3oa703g.png", srcSet: "https://framerusercontent.com/images/qBaq2Pok61msdnJ2jr3oa703g.png?scale-down-to=512 512w,https://framerusercontent.com/images/qBaq2Pok61msdnJ2jr3oa703g.png 800w"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1tqo0fn", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"TRxvv7Kl_"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"TRxvv7Kl_-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-02chZ.framer-sz47ey, .framer-02chZ .framer-sz47ey { display: block; }", ".framer-02chZ.framer-1tqo0fn { cursor: pointer; height: 32px; overflow: visible; position: relative; width: 32px; }", ".framer-02chZ.framer-v-1tqo0fn.hover.framer-1tqo0fn { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 32px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"evt3X4ixW":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerO8p5vuJzo: React.ComponentType<Props> = withCSS(Component, css, "framer-02chZ") as typeof Component;
export default FramerO8p5vuJzo;

FramerO8p5vuJzo.displayName = "Wonder_logo_circle_blue_with_white_BG";

FramerO8p5vuJzo.defaultProps = {height: 32, width: 32};

addFonts(FramerO8p5vuJzo, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})